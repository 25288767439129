/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 6/22/2022
 * @Example
 */

import React, {forwardRef, useEffect, useRef, useState, useImperativeHandle, useMemo} from "react";
import Config                           from "../../../../config";
import {useDispatch}                    from "react-redux";
import moment                           from "moment";
import * as W25F2018Actions             from "../../../../redux/W2X/W25F2018/W25F2018_actions";
import * as generalActions              from "../../../../redux/general/general_actions";
import {Column}                         from "devextreme-react/data-grid";
import GridContainer                    from "../../../grid-container/grid-container";
import GridActionBar                    from "../../../grid-container/grid-actionbar";
import W25F2303                         from "../W25F2303/W25F2303";
import History                          from "../../../libs/history";
import Filter                           from "../../../filter/filter";
import {
    Button,
    Row,
    Col,
    Dropdown,
    TextInput,
    ButtonIcon,
    Typography,
    Icon,
    DateRangePicker
} from "diginet-core-ui/components";
import UserImage from "../../../common/user/user-image";
const W25F2018GridLeft = forwardRef((props, ref) => {
    const { iPermission, loadListCandicate, data} = props;
    const {recInfoID , interviewerID, candidateID, canConfirmStatusID,
        stageID, interviewStatusID, interviewTypeID} = data
    const [isGridLoading, setIsGridLoading] = useState(null);
    const [dataGrid, setDataGrid] = useState({rows: [], total: 0});
    const [showW25F2303Popup, setShowW25F2303Popup] = useState(false);
    const [dataPopup, setDataPopup] = useState({});
    const [filterGrid, setFilterGrid] = useState({
            EmployeeID: "",
            DateFrom: null,
            DateTo: null
    });
    const [dataCboEmployees, setDataCboEmployees] = useState({rows: [], total: 0});
    const [isMultipleSchedule, setIsMultipleSchedule] = useState(false);
    const filter = useRef({skip: 0, limit: 10});
    const interviewScheduleName = useRef("");
    const filterCboEmployees = useRef({
        limit: 50,
        skip: 0,
        txtSearch: ""
    });
    const filterGridRef = useRef({
        EmployeeID: "",
        DateFrom: null,
        DateTo: null
    });
    const gridRef = useRef(null);
    const dispatch = useDispatch();

    const loadGridLeft = () => {
        const params = {
            FormID               : "W25F2018",
            RecInfoID            : recInfoID ?? "",
            Mode                 : 5,
            CandidateID          : candidateID ?? "",
            CanConfirmStatusID   : canConfirmStatusID ?? "",
            InterviewStatusID    : interviewStatusID ?? "",
            StageID              : stageID ?? "",
            InterviewType        : interviewTypeID ?? "",
            InterviewerID        : interviewerID ?? "",
            DateFrom             : filterGridRef.current.DateFrom || null,
            DateTo               : filterGridRef.current.DateTo || null,
            skip                 : filter.current.skip,
            limit                : filter.current.limit,
            InterviewScheduleID  : "",
            InterviewScheduleName: interviewScheduleName.current ?? "",
            UserIDD              : "",
            CreateDateFrom       : filterGridRef.current.CreateDateFrom || null,
            CreateDateTo         : filterGridRef.current.CreateDateTo || null,
        };
        setFilterGrid(filterGridRef.current);
        setIsGridLoading(true);
        dispatch(W25F2018Actions.getGridLeft(params,(errors, data) => {
            setIsGridLoading(false);
            if (errors) {
                Config.popup.show('ERROR', errors);
                return false;
            }
            if(gridRef.current) gridRef.current.instance.option("focusedRowKey", data?.rows?.[0]?.InterviewScheduleID || "");
            const rows = data && data.rows ? data.rows : data;
            const total = data && data.total ? data.total : data.length;
            setDataGrid({
                rows: rows,
                total: total,
            });
            loadListCandicate({
                InterviewScheduleID  : rows?.[0]?.InterviewScheduleID,
                InterviewScheduleName: interviewScheduleName.current ?? "",
                UserIDD              : "",
                CreateDateFrom       : filterGridRef.current.CreateDateFrom || null,
                CreateDateTo         : filterGridRef.current.CreateDateTo || null
            });
        }))
    };

    const loadCboEmployees = (isReset) => {
        const params = {
            HostID: "",
            Type: "EmployeeID",
            FormID: "W25F2018",
            skip: filterCboEmployees.current.skip,
            limit: filterCboEmployees.current.limit,
            search: filterCboEmployees.current.txtSearch
        };
        dispatch(generalActions.getCboEmployees(params, (error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            const rows = data?.rows || data;
            const total = data?.total || data.length;
            setDataCboEmployees({
                rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                total: total
            });
        }))
    };

    const filterChange = (key, e) => {
        const value = e?.value ?? e?.target?.value ?? "";
        if (key === "InterviewScheduleName") {
            interviewScheduleName.current = value;
        } else if (key === "Date" || key === "CreateDate") {
            const dateFrom = Array.isArray(value) ? value[0] : value;
            const dateTo = Array.isArray(value) ? value[1] : value;
            filterGridRef.current[`${key}From`] = dateFrom;
            filterGridRef.current[`${key}To`] = dateTo;
        } else {
            filterGridRef.current[key]= value;
        }
    };

    const onLoadDataFilter = () => {
        loadCboEmployees();
    };

    useEffect(() => {
        loadGridLeft();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useImperativeHandle(ref, () => ({
        loadGridLeft
    }));

    const onChangePage = (page) => {
        filter.current.skip = page * filter.current.limit;
        loadGridLeft();
    };

    const onChangePerPage = (per) => {
        filter.current.skip = 0;
        filter.current.limit = per;
        loadGridLeft();
    };

    const onClose = (isReLoad = false) => {
        setIsMultipleSchedule(false);
        setShowW25F2303Popup(false);
        if(isReLoad) loadGridLeft();
    };

    const onEdit = (e) => {
        const {data} = e?.row || {};
        setDataPopup({...data, RecInfoID: recInfoID});
        setIsMultipleSchedule(true);
        setShowW25F2303Popup(true);
    };

    const onDelete = (e) => {
        const {data: dataRow} = e?.row || {};
        const params = {
            CanConfirmStatusID: "",
            InterviewScheduleID: dataRow?.InterviewScheduleID ?? "",
        };
        Config.popup.show(
            "YES_NO",
            Config.lang("Ban_co_chac_muon_xoa?"),
            () => {
                dispatch(W25F2018Actions.onDelete(params,async (errors, data) => {
                    if (errors) {
                        Config.popup.show('ERROR', errors);
                        return false;
                    }
                    if(data?.Status === 1 && data?.MsgAsk === 0) {
                        Config.popup.show('INFO', data.Message);
                    }
                    Config.notify.show("success", Config.lang("Xoa_thanh_cong"), 2000);
                    await saveHistory(dataRow);
                    loadGridLeft();
                }))
            }
        );
    };

    const saveHistory = async (data) => {
        if(!data) return false;
        const InterviewScheduleID = data?.InterviewScheduleID ?? "";
        if (InterviewScheduleID) {
            const user = Config.getUser({EmployeeID: data?.EmployeeID ?? ""});
            // data Content
            const _data = {
                InterviewScheduleName: data?.InterviewScheduleName ?? "",
                Date: `${moment(data.DateFrom).format("DD/MM/YYYY")} - ${moment(data.DateTo).format("DD/MM/YYYY")}`,
                EmployeeName: user?.EmployeeName ?? "",
                CreateDate: moment(data?.CreateDate).format("DD/MM/YYYY") ?? "",
            };

            //name Content
            const captions = {
                InterviewScheduleName: "Dien_giai",
                Date: "Ngay_phong_van",
                EmployeeName: "Nguoi_lap",
                CreateDate: "Ngay_lap",
            };

            const options = {
                data: _data,
                captions: captions,
                action: 3,
                ModuleID: "D25",
                TransID: InterviewScheduleID,
                TransactionID: "W25F2018",
                TransactionName: Config.lang("Danh_sach_lich_phong_van"),
            };
            const history = new History(options); //Init history
            await history.save();
        } else {
            Config.notify.show("error", Config.lang("Luu_lich_su_khong_thanh_cong"), 2000);
        }

    };

    const cellRenderAction = (e) => {
        if(!e) return null;
        return (
            <GridActionBar>
                <ButtonIcon
                    circular={true}
                    size={"medium"}
                    viewType={"text"}
                    name={"edit"}
                    className={"mgr1x"}
                    disabled={iPermission <= 2}
                    onClick={() => {
                        onEdit(e)
                    }}
                />
                <ButtonIcon
                    circular={true}
                    size={"medium"}
                    viewType={"text"}
                    name={"delete"}
                    disabled={iPermission <= 3}
                    onClick={()=> onDelete(e)}
                />
            </GridActionBar>
        );
    };

    const cellRenderEmployeeInfo = (e) => {
        const {data} = e?.row || {};
        const user = Config.getUser({EmployeeID: data?.EmployeeID ?? ""});
        let createDate = Config.convertDate(data.CreateDate, '',"DD/MM/YYYY HH:mm:ss", true);
        return (
            <div className={"display_row align-center"}>
                <UserImage
                    width={42}
                    height={42}
                    data={user}
                    hoverAble
                    className={"mgr2x"}
                />
                <div>
                    <Typography type={"h3"}>{user?.EmployeeName}</Typography>
                    <div className={"display_row align-center"}>
                        <Icon
                            name={"calendar"}
                            width={20}
                            height={20}
                            className={"mgr5"}
                            color={Config.coreTheme?.colors?.system.rest}
                        />
                        <Typography
                            type={"p2"}
                            className={"display_row align-center"}
                            color={Config.coreTheme?.colors?.system.rest}
                        >
                            {createDate}
                        </Typography>
                    </div>
                </div>
            </div>
        )
    };

    const renderInfo = (e) => {
        const { data } = e?.row || {};
        let dateFrom = Config.convertDate(data.DateFrom, null, "DD/MM/YYYY");
        let dateTo = Config.convertDate(data.DateTo, null, "DD/MM/YYYY");
        return (
            <div className={"pdv2x"}>
                <Typography>
                    {Config.lang("Dien_giai")}: {data?.InterviewScheduleName ?? ""}
                </Typography>
                <Typography>
                    {Config.lang("Ngay_phong_van")}: {dateFrom} - {dateTo}
                </Typography>
            </div>
        );
    };

    const renderFilterData = () => {
        return (
            <Filter
                isUseDDCore={true}
                openOnFieldClick={true}
                placeholder={Config.lang("Noi_dung_can_tim_kiem")}
                onOpenLoaded={onLoadDataFilter}
                onClosed={() => {
                    filterGridRef.current = { ...filterGrid};
                }}
                renderFilter={() => {
                    return (
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <TextInput
                                    label={Config.lang("Dien_giai")}
                                    placeholder={Config.lang("Nhap")}
                                    viewType={"outlined"}
                                    defaultValue={interviewScheduleName.current ?? ""}
                                    onChange={e => filterChange("InterviewScheduleName", e)}
                                />
                            </Col>
                            <Col xs={12}>
                                <DateRangePicker
                                    clearAble
                                    controls
                                    defaultValue={[filterGrid.DateFrom, filterGrid.DateTo]}
                                    viewType={"outlined"}
                                    label={Config.lang("Ngay_phong_van")}
                                    onChange={e => filterChange("Date", e)}
                                    placeholder={"DD/MM/YY - DD/MM/YY"}
                                    returnFormat={"YYYY-MM-DD"}
                                />
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <Dropdown
                                    allowSearch
                                    clearAble
                                    dataSource={dataCboEmployees.rows}
                                    total={dataCboEmployees.total}
                                    skip={filterCboEmployees.current.skip}
                                    limit={filterCboEmployees.current.limit}
                                    displayExpr={"{EmployeeID} - {EmployeeName}"}
                                    renderSelectedItem={"EmployeeName"}
                                    valueExpr={"EmployeeID"}
                                    placeholder={Config.lang("Chon")}
                                    label={Config.lang("Nhan_vien")}
                                    onChange={e => filterChange("EmployeeID", e)}
                                    defaultValue={filterGrid?.EmployeeID ?? null}
                                    viewType={"outlined"}
                                    onInput={e => {
                                        filterCboEmployees.current.txtSearch = e?.target?.value ?? "";
                                        filterCboEmployees.current.skip = 0;
                                        loadCboEmployees(true);
                                    }}
                                    onLoadMore={e => {
                                        filterCboEmployees.current.skip = e?.skip ?? 0;
                                        filterCboEmployees.current.limit = e?.limit ?? 10;
                                        loadCboEmployees();
                                    }}
                                />
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <DateRangePicker
                                    clearAble
                                    controls
                                    defaultValue={[filterGrid.CreateDateFrom, filterGrid.CreateDateTo]}
                                    viewType={"outlined"}
                                    label={Config.lang("Ngay_lap")}
                                    onChange={e => filterChange("CreateDate", e)}
                                    placeholder={"DD/MM/YY - DD/MM/YY"}
                                    returnFormat={"YYYY-MM-DD"}
                                />
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} className={"display_row align-center valign-middle"}>
                                <Button
                                    size={"medium"}
                                    color={"primary"}
                                    viewType={"outlined"}
                                    label={Config.lang("Tim_kiem")}
                                    startIcon={"Search"}
                                    onClick={loadGridLeft}
                                />
                            </Col>
                        </Row>
                    );
                }}
            />
        );
    };

    const onCellClick = (e) => {
        const {data} = e?.row || {};
        if (e?.column?.dataField !== "Action" && data) {
            loadListCandicate({
                InterviewScheduleID  : data?.InterviewScheduleID ?? "",
                InterviewScheduleName: interviewScheduleName.current ?? "",
                UserIDD              : filterGrid.EmployeeID || null,
                CreateDateFrom       : filterGrid.CreateDateFrom || null,
                CreateDateTo         : filterGrid.CreateDateTo || null
            })
        }
    };

    const gridLeft = useMemo(()=>{
        return(
            <GridContainer
                reference={ref => gridRef.current = ref}
                style={{border: 'none'}}
                dataSource={dataGrid.rows}
                totalItems={dataGrid.total}
                keyExpr={"InterviewScheduleID"}
                height={Config.getHeightGrid() - 54}
                typePaging={"remote"}
                loading={isGridLoading}
                rowAlternationEnabled={false}
                showRowLines={true}
                showBorders={true}
                showColumnLines={false}
                showColumnHeaders={false}
                pagerFullScreen={false}
                noDataText={Config.lang("No_data")}
                onChangePage={onChangePage}
                onChangePerPage={onChangePerPage}
                onCellClick={onCellClick}
                selection={{mode: "none"}}
                focusedRowEnabled={true}
            >
                <Column
                    caption={Config.lang("Hanh_dong")}
                    alignment={"center"}
                    visible={Config.isMobile}
                    width={120}
                    dataField={"Action"}
                    name={"Action1"}
                    cellRender={cellRenderAction}
                />
                <Column
                    dataField={"InterviewScheduleID"}
                    visible={false}
                />
                <Column
                    dataField={"EmployeeID"}
                    visible={false}
                />
                <Column
                    minWidth={300} width={"20%"}
                    cellRender={cellRenderEmployeeInfo}
                />
                <Column width={650} cellRender={renderInfo} />
                <Column
                    allowEditing={false}
                    fixed={true}
                    fixedPosition={"right"}
                    width={120}
                    dataField={"Action"}
                    name={"Action"}
                    visible={!Config.isMobile}
                    cellRender={cellRenderAction}
                />
            </GridContainer>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[dataGrid, isGridLoading]);

    return (
        <>
            <div className={"hidden"}>{renderFilterData()}</div>
            {showW25F2303Popup ? <W25F2303
                open={showW25F2303Popup}
                data={dataPopup}
                mode={"edit"}
                onClose={onClose}
                FormID="W25F2018"
                isMultipleSchedule={isMultipleSchedule}
            /> : ""}
            {gridLeft}
        </>
    );
});

export default W25F2018GridLeft;
