/**
 * @copyright 2019 @ DigiNet
 * @author QUOCUY
 * @create 5/8/2020
 * @Example
 */

import React, { useState, useRef, useEffect } from "react";
import { FormGroup,  Image } from "react-bootstrap";
import Config from "../../../../config";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import Approvals from "../../../approvals/approvals";
import LabelText from "../../../common/label-text/label-text";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import * as W25F2041Actions from "../../../../redux/W2X/W25F2041/W25F2041_actions";
import { withStyles } from "@material-ui/core";
import InlineSVG from "react-inlinesvg";
import * as ApprovalActions from "../../../../redux/approvals/approvals_actions";
import moment from "moment";
import * as generalActions from "../../../../redux/general/general_actions";
import { Accordion, AccordionSummary, AccordionDetails, Col, Row, } from "diginet-core-ui/components";
import Filter from "../../../filter/filter";
import { Combo } from "../../../common/form-material";
import _ from "lodash";
import DateBoxPicker from "../../../common/form-material/date-box";
import { FormLabel as Label } from "@material-ui/core";
import ButtonGeneral from "../../../common/button/button-general";

const styles = {
    divAvatar: {
        width: 32,
        height: 32,
        borderRadius: "50%",
        border: "1px solid #ddd",
        overflow: "hidden",
        marginRight: 8,
    },
    imgAvatar: {
        maxWidth: "100%",
        maxHeight: "100%",
        objectFit: "contain",
    },
    label: {
        fontSize: "1rem",
        fontWeight: 200,
        color: "#757575",
    },
    divText: {
        fontWeight: 500,
    },
};

const W25F2041 = props => {
    const { getCboStatus, getCboCandidate, getCboDuty2 } = props;
    const [iPermission, setIPermission] = useState(0);
    const [dataForm, setDataForm] = useState({});
    const [voucherID, setVoucherID] = useState("");
    const [loading, setLoading] = useState(false);
    const [loadingCboAppStatus, setLoadingCboAppStatus] = useState(0);
    const [loadingCboCandidate, setLoadingCboCandidate] = useState(0);
    const [loadingCboDuty, setLoadingCboDuty] = useState(0);

    const Language = useRef(Config.language || "84");
    const toolFilter = useRef(null);
    const filter = useRef({
        ApprovalStatus: null,
        DutyID: null,
        CandidateID: null,
        DateFrom: null,
        DateTo: null,
    });

    const loadPermission = () => {
        props.generalActions.getPermission("W25F2041", isPer => {
            setIPermission(isPer);
        });
    };

    const loadCaption = () => {
        props.W25F2041Actions.loadSalaryCaption(errors => {
            if (errors) {
                let message = errors.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
        });
        props.W25F2041Actions.loadCoefficientCaption(errors => {
            if (errors) {
                let message = errors.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
        });
    };

    useEffect(() => {
        loadPermission();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!iPermission) return;
        loadCaption();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iPermission]);

    const onSelectionChanged = e => {
        if (!e) return false;
        loadFormInfo(e.VoucherID);
    };

    const onDrawerClosed = () => {
        setVoucherID("");
    };

    const loadFormInfo = (VoucherID, cb) => {
        const params = {
            DivisionID: Config.getDivisionID() || "",
            FormID: "W25F2041",
            Language: Language.current,
            VoucherID: VoucherID ? VoucherID : "",
        };
        setLoading(true);
        props.approvalActions.getFormInfo(params, (error, data) => {
            setLoading(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                setDataForm(data);
                cb && cb(data);
            }
        });
    };

    const openNewTabW25F2030 = data => {
        const dataW25F2023 = {
            CandidateID: Config.helpers.getObjectValue(data, "CandidateID", ""),
            RecInfoID: Config.helpers.getObjectValue(data, "RecInfoID", ""),
            isViewOnly: true,
        };
        Config.setLocalStorage("W25F2023", JSON.stringify(dataW25F2023));
        window.open(Config.getRootPath() + "W25F2023", "_blank").focus();
    };

    const loadGrid = () => {
        const params = {
            FormID: "W25F2041",
            Language: Config.language || "84",
            DivisionID: Config.getDivisionID(),
            ...filter.current,
        };
        props.approvalActions.getGridApprovals(params, err => {
            if (err) return Config.popup.show("ERROR", err);
        });
    };

    const onSearch = () => {
        toolFilter.current.instance.close();
        loadGrid();
    };

    const handleFilterChange = (key, e) => {
        const value = _.get(e, "value", _.get(e, "target.value", null));
        switch (key) {
            case "ApprovalStatus":
                return (filter.current.ApprovalStatus = value);
            case "CandidateID":
                return (filter.current.CandidateID = value);
            case "DutyID":
                return (filter.current.DutyID = value);
            case "DateFrom":
                return (filter.current.DateFrom = value);
            case "DateTo":
                return (filter.current.DateTo = value);
            default:
                return false;
        }
    };

    const loadCboAppStatus = () => {
        const params = {
            FormID: "STANDARD",
            Language: Config.language || "84",
        };
        setLoadingCboAppStatus(true);
        props.approvalActions.getCboStatus(params, err => {
            setLoadingCboAppStatus(false);
            if (err) return Config.popup.show("ERROR", err);
        });
    };

    const loadCboCandidate = () => {
        const params = {
            Language: Config.language || "84",
        };
        setLoadingCboCandidate(true);
        props.W25F2041Actions.getCboCandidate(params, error => {
            setLoadingCboCandidate(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    const loadCboDuty = () => {
        setLoadingCboDuty(true);
        props.generalActions.getCboDuty2(error => {
            setLoadingCboDuty(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    const onLoadDataFilter = () => {
        loadCboAppStatus();
        loadCboCandidate();
        loadCboDuty();
    };

    const renderFormInfo = data => {
        const { classes } = props;
        let { getSalaryCaption, getCoefficientCaption } = props;
        const CandidateName = Config.helpers.getObjectValue(data, "CandidateName", "");
        const CandidateID = Config.helpers.getObjectValue(data, "CandidateID", "");
        const CandidatePictureURL = Config.helpers.getObjectValue(data, "CandidatePictureURL", "");
        const BeginDate = Config.helpers.getObjectValue(data, "BeginDate", "");
        const TrialDateFrom = Config.helpers.getObjectValue(data, "TrialDateFrom", "");
        const TrialDateTo = Config.helpers.getObjectValue(data, "TrialDateTo", "");
        const RecpositionName = Config.helpers.getObjectValue(data, "RecpositionName", "");
        const DepartmentName = Config.helpers.getObjectValue(data, "DepartmentName", "");
        const ProjectName = Config.helpers.getObjectValue(data, "ProjectName", "");
        const TeamName = Config.helpers.getObjectValue(data, "TeamName", "");
        const WorkName = Config.helpers.getObjectValue(data, "WorkName", "");
        const DirectManagerName = Config.helpers.getObjectValue(data, "DirectManagerName", "");
        const RecruitmentFileName = Config.helpers.getObjectValue(data, "RecruitmentFileName", "");
        const DetailNotes = Config.helpers.getObjectValue(data, "DetailNotes", "");
        const SalEmpGroupID = Config.helpers.getObjectValue(data, "SalEmpGroupID", "");
        const SalEmpGroupName = Config.helpers.getObjectValue(data, "SalEmpGroupName", "");
        return (
            <>
                {(CandidatePictureURL || CandidateName || CandidateID) && (
                    <FormGroup className={"mgb0"}>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} className={"mgb15"}>
                                <LabelText
                                    allowPadding={true}
                                    label={Config.lang("Ung_vien")}
                                    value={() => {
                                        return (
                                            <div
                                                className={"display_row align-center pdb5"}
                                                style={{ height: 37, textDecoration: "underline" }}
                                            >
                                                <div className={classes.divAvatar}>
                                                    {CandidatePictureURL ? (
                                                        <Image
                                                            className={classes.imgAvatar}
                                                            src={data.CandidatePictureURL}
                                                        />
                                                    ) : (
                                                        <InlineSVG
                                                            className={classes.imgAvatar}
                                                            src={require("../../../../assets/images/general/user_default.svg")}
                                                        />
                                                    )}
                                                </div>
                                                <div
                                                    className="MuiInputBase-root"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => openNewTabW25F2030(data)}
                                                >
                                                    {`${CandidateName} - ${CandidateID}`}
                                                </div>
                                            </div>
                                        );
                                    }}
                                    fullWidth={true}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                )}
                {(BeginDate || (TrialDateFrom && TrialDateTo)) && (
                    <FormGroup className={"mgb0"}>
                        <Row>
                            {BeginDate && (
                                <Col xs={12} sm={12} md={6} lg={6} className={"mgb15"}>
                                    <LabelText
                                        allowPadding={true}
                                        label={Config.lang("Ngay_vao_lam_du_kien")}
                                        fullWidth={true}
                                        value={BeginDate && moment(BeginDate).format("DD/MM/YYYY")}
                                    />
                                </Col>
                            )}
                            {TrialDateFrom && TrialDateTo && (
                                <Col xs={12} sm={12} md={6} lg={6} className={"mgb15"}>
                                    <LabelText
                                        allowPadding={true}
                                        label={Config.lang("Thoi_gian_thu_viec")}
                                        fullWidth={true}
                                        value={`${Config.helpers.getObjectValue(data, "TrialPeriod", "")} ${Config.lang(
                                            "thang"
                                        )} (${TrialDateFrom && moment(TrialDateFrom).format("DD/MM/YYYY")} - ${
                                            TrialDateTo && moment(TrialDateTo).format("DD/MM/YYYY")
                                        })`}
                                    />
                                </Col>
                            )}
                        </Row>
                    </FormGroup>
                )}
                {(RecpositionName || ProjectName) && (
                    <FormGroup className={"mgb0"}>
                        <Row>
                            {RecpositionName && (
                                <Col xs={12} sm={12} md={6} lg={6} className={"mgb15"}>
                                    <LabelText
                                        allowPadding={true}
                                        label={Config.lang("Vi_tri_tuyen_dung")}
                                        fullWidth={true}
                                        value={RecpositionName}
                                    />
                                </Col>
                            )}
                            {ProjectName && (
                                <Col xs={12} sm={12} md={6} lg={6} className={"mgb15"}>
                                    <LabelText
                                        allowPadding={true}
                                        label={Config.lang("Du_an")}
                                        fullWidth={true}
                                        value={ProjectName}
                                    />
                                </Col>
                            )}
                        </Row>
                    </FormGroup>
                )}
                {(DepartmentName || TeamName) && (
                    <FormGroup className={"mgb0"}>
                        <Row>
                            {DepartmentName && (
                                <Col xs={12} sm={12} md={6} lg={6} className={"mgb15"}>
                                    <LabelText
                                        allowPadding={true}
                                        label={Config.lang("Phong_ban")}
                                        fullWidth={true}
                                        value={DepartmentName}
                                    />
                                </Col>
                            )}
                            {TeamName && (
                                <Col xs={12} sm={12} md={6} lg={6} className={"mgb15"}>
                                    <LabelText
                                        allowPadding={true}
                                        label={Config.lang("To_nhom")}
                                        fullWidth={true}
                                        value={TeamName}
                                    />
                                </Col>
                            )}
                        </Row>
                    </FormGroup>
                )}
                {WorkName && (
                    <FormGroup>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <LabelText
                                    allowPadding={true}
                                    label={Config.lang("Cong_viec")}
                                    fullWidth={true}
                                    value={WorkName}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                )}
                {DirectManagerName && (
                    <FormGroup>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <LabelText
                                    allowPadding={true}
                                    label={Config.lang("Nguoi_quan_ly_truc_tiep")}
                                    fullWidth={true}
                                    value={DirectManagerName}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                )}
                {RecruitmentFileName && (
                    <FormGroup>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <LabelText
                                    allowPadding={true}
                                    label={Config.lang("De_xuat_tuyen_dung")}
                                    fullWidth={true}
                                    value={RecruitmentFileName}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                )}
                {DetailNotes && (
                    <FormGroup>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <LabelText
                                    allowPadding={true}
                                    label={Config.lang("Ghi_chu")}
                                    fullWidth={true}
                                    value={DetailNotes}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                )}
                <FormGroup>
                    <Row>
                        <Accordion>
                            <AccordionSummary>{Config.lang("Thong_tin_luong")}</AccordionSummary>
                            <AccordionDetails>
                                <Row>
                                    {(SalEmpGroupID || SalEmpGroupName) && (
                                        <Col xs={12} sm={12} md={12} lg={12} >
                                            <LabelText
                                                allowPadding={true}
                                                label={Config.lang("Nhom_luong")}
                                                fullWidth={true}
                                                readOnly
                                                value={SalEmpGroupName}
                                            />
                                        </Col>
                                    )}
                                    {getSalaryCaption &&
                                        getSalaryCaption.map((items, index) => {
                                            const value = Config.helpers.getObjectValue(
                                                data,
                                                `BaseSalary${getSalaryCaption[index].Code.substr(-2)}`,
                                                ""
                                            );
                                            if (getSalaryCaption[index].Disabled === 0 && value)
                                                return (
                                                    <Col xs={12} sm={12} md={6} lg={6} >
                                                        <LabelText
                                                            allowPadding={true}
                                                            label={items.CaptionName}
                                                            fullWidth={true}
                                                            value={value}
                                                        />
                                                    </Col>
                                                );
                                            return null;
                                        })}
                                    {getCoefficientCaption &&
                                        getCoefficientCaption.map((items, index) => {
                                            const value = Config.helpers.getObjectValue(
                                                data,
                                                `SalCoefficient${getCoefficientCaption[index].Code.substr(-2)}`,
                                                ""
                                            );
                                            if (getCoefficientCaption[index].Disabled === 0 && value)
                                                return (
                                                    <Col xs={12} sm={12} md={6} lg={6} className={"mgb15"}>
                                                        <LabelText
                                                            allowPadding={true}
                                                            label={items.CaptionName}
                                                            fullWidth={true}
                                                            value={value}
                                                        />
                                                    </Col>
                                                );
                                            return null;
                                        })}
                                </Row>
                            </AccordionDetails>
                        </Accordion>
                    </Row>
                </FormGroup>
            </>
        );
    };

    const renderFilter = () => {
        return (
            <Filter
                dropdownProps={{
                    ref: ref => (toolFilter.current = ref),
                }}
                placeholder={Config.lang("Noi_dung_can_tim_kiem")}
                showHeader={false}
                openOnFieldClick={true}
                onOpenLoaded={onLoadDataFilter}
                renderFilter={() => (
                    <>
                        <FormGroup>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <Combo
                                        dataSource={getCboStatus}
                                        showClearButton={true}
                                        loading={loadingCboAppStatus}
                                        displayExpr={"AppStatusName"}
                                        valueExpr={"ApprovalStatus"}
                                        value={filter.current.ApprovalStatus}
                                        stylingMode={"outlined"}
                                        label={Config.lang("Trang_thai_duyet")}
                                        onValueChanged={e => handleFilterChange("ApprovalStatus", e)}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <Combo
                                        dataSource={getCboCandidate}
                                        showClearButton={true}
                                        loading={loadingCboCandidate}
                                        displayExpr={"CandidateName"}
                                        valueExpr={"CandidateID"}
                                        value={filter.current.CandidateID}
                                        stylingMode={"outlined"}
                                        label={Config.lang("Ung_vien")}
                                        onValueChanged={e => handleFilterChange("CandidateID", e)}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <Combo
                                        dataSource={getCboDuty2}
                                        showClearButton={true}
                                        loading={loadingCboDuty}
                                        displayExpr={"DutyName"}
                                        valueExpr={"DutyID"}
                                        value={filter.current.DutyID}
                                        stylingMode={"outlined"}
                                        label={Config.lang("Vi_tri_ung_tuyen")}
                                        onValueChanged={e => handleFilterChange("DutyID", e)}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={6} md={6} lg={6}>
                                    <div className={"display_row align-center"}>
                                        <Label className={"mgr5"}>{Config.lang("Tu")}</Label>
                                        <DateBoxPicker
                                            max={filter.current.DateTo}
                                            useMaskBehavior={true}
                                            showClearButton={true}
                                            placeholder={"DD/MM/YYYY"}
                                            width={"100%"}
                                            value={filter.current.DateFrom}
                                            onValueChanged={e => handleFilterChange("DateFrom", e)}
                                        />
                                    </div>
                                </Col>
                                <Col xs={12} sm={6} md={6} lg={6}>
                                    <div className={"display_row align-center"}>
                                        <Label className={"mgr5"}>{Config.lang("Den")}</Label>
                                        <DateBoxPicker
                                            min={filter.current.DateFrom}
                                            useMaskBehavior={true}
                                            showClearButton={true}
                                            placeholder={"DD/MM/YYYY"}
                                            width={"100%"}
                                            value={filter.current.DateTo}
                                            onValueChanged={e => handleFilterChange("DateTo", e)}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup className={"display_row align-center valign-middle"}>
                            <ButtonGeneral
                                name={Config.lang("Tim_kiem")}
                                typeButton={"search"}
                                color={"primary"}
                                className={"pdl10 pdr10"}
                                variant={"outlined"}
                                style={{ textTransform: "uppercase" }}
                                size={"large"}
                                onClick={onSearch}
                            />
                        </FormGroup>
                    </>
                )}
            />
        );
    };

    return (
        <React.Fragment>
            <FormGroup>
                <ActionToolbar title={Config.lang("Duyet_de_xuat_offer")} />
            </FormGroup>
            <FormGroup>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <Approvals
                            loading={loading}
                            parentProps={props}
                            FormID={"W25F2041"}
                            dataForm={dataForm}
                            selectedRowKey={voucherID}
                            singleClick
                            formInfoRender={renderFormInfo}
                            onSelectionChanged={onSelectionChanged}
                            onDrawerClosed={onDrawerClosed}
                            filterRender={renderFilter}
                        />
                    </Col>
                </Row>
            </FormGroup>
        </React.Fragment>
    );
};

export default compose(
    connect(
        state => ({
            getSalaryCaption: state.W25F2041.getSalaryCaption,
            getCoefficientCaption: state.W25F2041.getCoefficientCaption,
            getCboCandidate: state.W25F2041.getCboCandidate,
            getCboStatus: state.approvals.getCboStatus,
            getCboDuty2: state.general.getCboDuty2,
        }),
        dispatch => ({
            generalActions: bindActionCreators(generalActions, dispatch),
            W25F2041Actions: bindActionCreators(W25F2041Actions, dispatch),
            approvalActions: bindActionCreators(ApprovalActions, dispatch),
        })
    ),
    withStyles(styles, { withTheme: true })
)(W25F2041);
