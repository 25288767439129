import React, {Component} from 'react';
import {Col, FormGroup, Row} from "react-bootstrap";
import CandidateProFileCard from "../Components/CandidateProFileCard";
import CandidateCVCard from "../Components/CandidateCVCard";
import PropTypes from "prop-types";
import Modal from "../../../common/modal/modal";
import W25F2012 from "../W25F2012/W25F2012";
import {Button, Icon, IconButton, withStyles} from "@material-ui/core";
import _ from "lodash";
import {compose} from "redux";
import {connect} from "react-redux";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import CloseIcon from "@material-ui/icons/Close";
import Config from "../../../../config";
import TransRecPopup from "../Components/TransRecPopup";

const styles = () => ({
    modalTitle: {
        "& > div[class*='ActionToolbar-root']": {
            alignItems: "center"
        }
    },
    containerCard: {
        padding: "15px 0",
        backgroundColor: "transparent"
    },
    flexAlignCenter: {
        display: "flex",
        alignItems: "center",
    },
})

class W25F2022 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            iPropsData: 0,
            recInfoIDUpdated: "",
            isLoadingCandidateInfo: false,
            showModalTransRecruitment: false,
        }
    }

    onNextCandidate = () => {
        const {iPropsData} = this.state;
        this.setState({
            iPropsData: iPropsData + 1
        })
    };

    onPrevCandidate = () => {
        const {iPropsData} = this.state;
        this.setState({
            iPropsData: iPropsData - 1
        })
    };

    onCloseModal = () => {
        this.setState({showModalTransRecruitment: false}, () => {
        })
    };

    renderCandidateProfileCard = (propsCandidateProfile) => {
        const {mode, data, dataParam, isLoading} = this.props;
        const {iPropsData} = this.state;
        const candidateID = _.get(data, "CandidateID",
            _.get(data, `[${iPropsData}]CandidateID`,
                _.get(dataParam, `[${iPropsData}]CandidateID`, ""))
        );
        const propsLoading = _.isUndefined(isLoading) ? {} : {isLoading: _.get(isLoading, "candidateProfileCard", false)};
        return (
            <>
                <FormGroup>
                    <CandidateProFileCard {...propsLoading} {...propsCandidateProfile}
                                          mode={mode === "tab" ? "apply" : ""}/>
                </FormGroup>
                <FormGroup>
                    <CandidateCVCard modeView={(mode === "popup" || mode === "tab") ? "multi" : "default"}
                                     keyID={candidateID}/>
                </FormGroup>
            </>
        )
    }

    renderModalTitle = () => {
        const {iPropsData, isLoadingCandidateInfo} = this.state;
        const {data, onClosePopup, classes, loadInfoCandidate, isViewOnly} = this.props;
        const candidateName = _.get(data, `[${iPropsData}]CandidateName`, _.get(data, "CandidateName", ""));
        const dataSize = _.size(data)
        const isMultiCandidate = _.isArray(data) ? dataSize > 0 : false;
        const candidateOnTotal = `${iPropsData + 1} / ${dataSize}`;
        const isMoved = !!_.get(loadInfoCandidate,"[0]IsMoved", _.get(loadInfoCandidate,"IsMoved", 0));
        const isDisableTransRec = isMoved || _.get(loadInfoCandidate, "[0]StageID", "") === "HIRED";
        const isEmployee = !!_.get(loadInfoCandidate,"[0]IsEmployee", _.get(loadInfoCandidate,"IsEmployee", 0));
        const ApproveLevel = loadInfoCandidate[0]?.ApproveLevel || 0;
        const ApprovalStatus = loadInfoCandidate[0]?.ApprovalStatus || 0;
        return (
            <ActionToolbar
                alignment={"flex-end"}
                title={candidateName}
                showBorder={false}
                className={classes.modalTitle}
                style={{
                    marginTop: 0,
                }}
            >
                {isMultiCandidate ?
                    <div className={classes.flexAlignCenter}>
                        <Button
                            disabled={isDisableTransRec || isEmployee || (ApproveLevel > 1 && ApprovalStatus === 0) || isViewOnly}
                            onClick={() => this.setState({showModalTransRecruitment: true})}
                            style={{marginRight: 15}} variant="contained" color="primary"
                            disableElevation>
                            {Config.lang("Chuyen_tin_tuyen_dung")}
                        </Button>
                        <IconButton
                            size="small"
                            onClick={this.onPrevCandidate}
                            color="primary"
                            disabled={iPropsData === 0 || isLoadingCandidateInfo || (ApproveLevel > 1 && ApprovalStatus === 0)}
                        >
                            <Icon className="fas fa-chevron-circle-left"/>
                        </IconButton>
                        <div style={{justifyContent: "center", width: 45}} className={classes.flexAlignCenter}>
                            <span>{candidateOnTotal}</span>
                        </div>
                        <IconButton
                            size="small"
                            onClick={this.onNextCandidate}
                            color="primary"
                            disabled={(dataSize === iPropsData + 1) || isLoadingCandidateInfo || (ApproveLevel > 1 && ApprovalStatus === 0)}
                        >
                            <Icon className="fas fa-chevron-circle-right"/>
                        </IconButton>
                    </div>
                    :
                    ""
                }
                <IconButton aria-label="close" onClick={onClosePopup}>
                    <CloseIcon/>
                </IconButton>
            </ActionToolbar>
        )
    }

    renderCandidateProfilePopup = () => {
        const {data, openPopup, classes} = this.props;
        const {iPropsData, showModalTransRecruitment, recInfoIDUpdated} = this.state;
        const propsData = {data: _.get(data, `[${iPropsData}]`, data)};
        return (
            <>
                {showModalTransRecruitment ?
                    <TransRecPopup
                        onUpdateTransRec={recInfoIDUpdated => this.setState({recInfoIDUpdated})}
                        paramsProps={{..._.get(propsData, "data", {}), FormID: "W25F2022"}}
                        isOpen={showModalTransRecruitment}
                        onClose={(isReload) => this.onCloseModal(isReload)}/> :
                    ""}
                <Modal
                    open={openPopup}
                    maxWidth={"xl"}
                    fullWidth={true}>
                    <Modal.Title disableTypography>
                        {this.renderModalTitle()}
                    </Modal.Title>
                    <Modal.Content style={{backgroundColor: "rgba(226,226,229)"}}>
                        <Row className={classes.containerCard}>
                            <Col lg={8} xs={12}>
                                {this.renderCandidateProfileCard(propsData)}
                            </Col>
                            <Col lg={4} xs={12}>
                                <W25F2012 fullCandidateInfo={data} recInfoIDChange={recInfoIDUpdated} {...propsData}/>
                            </Col>
                        </Row>
                    </Modal.Content>
                </Modal>
            </>
        )
    }

    render() {
        const {mode, data} = this.props;
        let candidateComponent = "";
        switch (mode) {
            case "tab":
                candidateComponent = this.renderCandidateProfileCard({data})
                break;
            case "popup":
                candidateComponent = this.renderCandidateProfilePopup()
                break;
            default:
                break;
        }
        return candidateComponent;
    }
}

W25F2022.propTypes = {
    data: PropTypes.oneOfType([
        PropTypes.array,// { CandidateID, RecInfoID, InterviewFileID, CandidateName, CandidateEmail, ...}
        PropTypes.object, // [{ CandidateID, RecInfoID, InterviewFileID, CandidateName, CandidateEmail, ...}...]
    ]), // passed only if `dataParam` not provided
    dataParam: PropTypes.oneOfType([
        PropTypes.array,// [{CandidateID, RecInfoID},{...}]
        PropTypes.object, // { CandidateID, RecInfoID }
    ]), // passed only if `data` not provided
    mode: PropTypes.oneOf(["tab", "popup", ""]),
    formID: PropTypes.string,
    onClosePopup: PropTypes.func,
    openPopup: PropTypes.bool,
    isLoading: PropTypes.object,
};

W25F2022.defaultProps = {
    mode: "popup",
    openPopup: false,
};

export default compose(
    connect(
        (state) => ({
            getCandidateProfile: state.general.getCandidateProfile,
            loadInfoCandidate: state.W25F2012.loadInfoCandidate,
        }),
        null,
    ),
    withStyles(styles, {withTheme: true}),
)(W25F2022);