/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 10/1/2020
 * @Example
 */
import React from "react";
import { connect } from "react-redux";
import * as W25F2018Actions from "../../../../redux/W2X/W25F2018/W25F2018_actions";
import { bindActionCreators, compose } from "redux";
import { browserHistory } from "react-router";
import Config from "../../../../config";

import W25F2022Popup from "../W25F2022/W25F2022";
import W25F2018GridLeft from "./W25F2018GridLeft";
import GridContainer from "../../../grid-container/grid-container";
import * as generalActions from "../../../../redux/general/general_actions";

import { Column } from "devextreme-react/data-grid";
import _ from "lodash";
import withStyles from "@material-ui/core/styles/withStyles";
import { Button, Row, Col, Image, Typography, Divider, Icon, FormGroup } from "diginet-core-ui/components";
import moment from "moment";
import 'moment/locale/vi';

const styles = () => {
    return {
        imgAvatar: {
            maxWidth: "100%",
            maxHeight: "100%",
            objectFit: "contain",
        },
        imageRounded: {
            height: 42,
            width: 42,
            "& > img": {
                objectFit: "cover",
                height: 42,
                width: 42,
            },
        },
        textBlue: {
            color: "#0095FF"
        },
        textGray: {
            color: "#7F828E",
            fontSize: 14,
            fontWeight: 400
        },
        textTitle: {
            paddingLeft: "8px",
            fontWeight: 500,
            fontSize: 16
        },
        iconGroup: {
            width: 20,
            padding: 0
        },
        focusedRow: {
            '& .dx-datagrid-content': {
                '& tr.dx-selection > td': {
                    backgroundColor: "transparent !important"
                }
            }
        },
        iconSmall: {
            fontSize: 14,
            marginRight: 10,
        },
        imgCadidatePicture: {
            borderRadius: "50%",
            width: "40",
            height: "40"
        }
    }
};

class W25F2018 extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            dataGrid: [],
            gridLoading: false,
            UpdatedInterviewConfirm: "",
            UpdatedInterviewStatus: "",
            rowData: null,
            mode: "",
            showW25F2022Popup: false,
            interviewDateFrom: null,
            interviewDateTo: null,
            candidateID: "",
            canConfirmStatusID: "",
            interviewStatusID: "",
            interviewTypeID: "",
            stageID: "",
            interviewerID: "",
            iPermission: 0,
            dataListCandicates: {rows: [], total: 0},
            InterviewScheduleID: '',
            InterviewScheduleName: '',
            CreateDateFrom: '',
            CreateDateTo: ''
        };
        this.filter = {
            limit: 10,
            skip: 0
        };
        this.dataGrid = null;
        this.nameDay = "";
        this.refW25F2018Left = null;
    }

    loadPermission = async () => {
        await this.props.generalActions.getPermission("W25F2010", (isPer) => {
            this.setState({iPermission: isPer});
        });
    };

    loadListCandicate = (dataRow, dataFilter) => {

        const {RecInfoID} = this.props;

        dataFilter = {...this.state, ...dataFilter};
        const {
            InterviewDateFrom,
            InterviewDateTo,
            InterviewerID,
            CandidateID,
            CanConfirmStatusID,
            StageID,
            InterviewStatusID,
            InterviewTypeID,
        } = dataFilter;

        const { InterviewScheduleID, CreateDateFrom, CreateDateTo, InterviewScheduleName, UserIDD } = !_.isEmpty(dataRow)
            ? dataRow
            : dataFilter;

        if (!_.isEmpty(dataRow)) {
            this.setState({
                InterviewScheduleID,
                CreateDateFrom,
                CreateDateTo,
                InterviewScheduleName,
                UserIDD,
            });
        }

        const params = {
            FormID: "W25F2303",
            Mode: 2,
            RecInfoID: RecInfoID ?? "",
            Language: Config.language || 84,
            CandidateID: CandidateID ?? "",
            CanConfirmStatusID: CanConfirmStatusID ?? "",
            InterviewStatusID: InterviewStatusID ?? "",
            StageID: StageID ?? "",
            InterviewType: InterviewTypeID ?? "",
            InterviewerID: InterviewerID ?? "",
            InterviewDateFrom: InterviewDateFrom || null,
            InterviewDateTo: InterviewDateTo || null,
            InterviewScheduleID: InterviewScheduleID ||  "",
            InterviewScheduleName: InterviewScheduleName || "",
            UserIDD: UserIDD || "",
            CreateDateFrom: CreateDateFrom || null,
            CreateDateTo: CreateDateTo || null,
            skip: this.filter.skip,
            limit: this.filter.limit,
        };
        this.setState({gridLoading: true});
        this.props.w25F2018Actions.getListCandicate(params,(errors, data) => {
            this.setState({gridLoading: false});
            if (errors) {
                Config.popup.show('ERROR', errors);
                return false;
            }
            const rows = data?.rows || data;
            const total = data?.total || data.length;
            this.setState({
                dataListCandicates: {
                    rows: rows,
                    total: total
                }
            })
        });
    };

    updateDataInterviewConfirm = (dataRow = {}, CanConfirmStatusID) => {
        const {data} = dataRow;
        const params = {
            CanConfirmStatusID: CanConfirmStatusID ? CanConfirmStatusID : "",
            RecInfoID: data && data.RecInfoID ? data.RecInfoID : "",
            // RecInfoID: "46765BD2-56F7-43BC-81B1-9AF2912D846F",
            InterviewFileID: data && data.InterviewFileID ? data.InterviewFileID : "",
            CandidateID: data && data.CandidateID ? data.CandidateID : "",
        };
        Config.popup.show("YES_NO", Config.lang("Ban_co_chac_muon_cap_nhat?"), () => {
                this.props.w25F2018Actions.updateInterviewConfirm(params, (error) => {
                    if (error) {
                        Config.popup.show('ERROR', error);
                        return false;
                    } else {
                        Config.notify.show("success", Config.lang("Cap_nhat_thanh_cong"), 2000);
                        if (this.dataGrid) {
                            this.dataGrid.instance.cellValue(dataRow.rowIndex, "CanConfirmStatusID", CanConfirmStatusID);
                            this.dataGrid.instance.saveEditData();
                        }
                    }
                });
            }
        );

    };

    updateDataInterviewStatus = (dataRow = {}, InterviewStatusID) => {
        const {data} = dataRow;
        const param = {
            InterviewStatusID: InterviewStatusID ? InterviewStatusID : "",
            RecInfoID: data && data.RecInfoID ? data.RecInfoID : "",
            // RecInfoID: "46765BD2-56F7-43BC-81B1-9AF2912D846F",
            InterviewFileID: data && data.InterviewFileID ? data.InterviewFileID : "",
            CandidateID: data && data.CandidateID ? data.CandidateID : "",
        };

        Config.popup.show("YES_NO", Config.lang("Ban_co_chac_muon_cap_nhat?"), () => {
                this.props.w25F2018Actions.updateInterviewStatus(param, error => {
                    if (error) {
                        Config.popup.show("ERROR", error);
                        return false;
                    } else {
                        Config.notify.show("success", Config.lang("Cap_nhat_thanh_cong"), 2000);
                        if (this.dataGrid) {
                            this.dataGrid.instance.cellValue(dataRow.rowIndex, "InterviewStatusID", InterviewStatusID);
                            this.dataGrid.instance.saveEditData();
                        }
                    }
                });

            }
        );

    };

    setDataFilter = () => {
        const {dataFilter} = this.props;
        this.setState({
            CandidateID: dataFilter?.CandidateID ?? "",
            CanConfirmStatusID: dataFilter?.CanConfirmStatusID ?? "",
            InterviewStatusID: dataFilter?.InterviewStatusID ?? "",
            StageID: dataFilter?.StageID ?? "",
            InterviewTypeID: dataFilter?.InterviewTypeID ?? "",
            InterviewerID: dataFilter?.InterviewerID ?? "",
            InterviewDateFrom: dataFilter?.InterviewDateFrom || null,
            InterviewDateTo: dataFilter?.InterviewDateTo || null,
        });
    };

    loadGridW25F2018Left = () => {
        this.refW25F2018Left && this.refW25F2018Left.loadGridLeft();
    };

    componentDidMount = async () => {
        await this.loadPermission();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(this.props.dataFilter) !== JSON.stringify(prevProps.dataFilter)) {
            this.setDataFilter();
        }
    }

    redirectScreen = (e = "") => {
        browserHistory.push({
            pathname: Config.getRootPath() + "W25F2033",
            state: {
                CandidateID: _.get(e, "CandidateID", ""),
                RecInfoID: _.get(e, "RecInfoID", ""),
                InterviewFileID: _.get(e, "InterviewFileID", ""),
                RecInfoTitle: _.get(this.props, "RecInfoTitle", ""),
                ScreenID: "W25F2015"
            },
        });
    };

    renderEmpProfile = e => {
        const {data = {}} = e.row;
        return (
            <div className={"display_row align-center pdt10 pdb10"}>
                <Image
                    width={42}
                    height={42}
                    circular
                    className={"mgr2x"}
                    src={Config.getUserPicture(data?.candidatePictureURL)}
                />
                <div className={"w75f2000_info"}>
                    <Button
                        color="info"
                        viewType="link"
                        onClick={() => this.redirectScreen(data)}
                        labelProps={{
                            style:{textTransform:"capitalize"},
                            type:"h3"
                        }}
                    >
                        {data.CandidateName || ""}
                    </Button>
                    <Typography color={"dark6"}>
                        {data.SexName && data.SexName + ", "}
                        {data.BirthDate && Config.convertDate(data.BirthDate, "", "DD/MM/YYYY", true)}
                    </Typography>
                </div>
            </div>
        );
    };

    renderButtonConfirm = (e) => {
        const {data} = e;
        return (
                <div className={"display_row"}>
                    <Button
                        size={"small"}
                        viewType={"text"}
                        color={data && data.CanConfirmStatusID.toLocaleUpperCase() === "YES" ? "success" : "default"}
                        label={"Yes"}
                        startIcon={"Approval"}
                        onClick={() => this.updateDataInterviewConfirm(e, "YES")}
                    />
                    <Divider direction={"vertical"}/>
                    <Button
                        size={"small"}
                        viewType={"text"}
                        color={data && data.CanConfirmStatusID.toLocaleUpperCase() === "MAYBE" ? "warning" : "default"}
                        label={"MayBe"}
                        startIcon={"QuestionMark"}
                        onClick={() => this.updateDataInterviewConfirm(e, "MayBe")}
                    />
                    <Divider direction={"vertical"}/>
                    <Button
                        size={"small"}
                        viewType={"text"}
                        color={data && data.CanConfirmStatusID.toLocaleUpperCase() === "NO" ? "danger" : "default"}
                        label={"No"}
                        startIcon={"Cancel"}
                        onClick={() => this.updateDataInterviewConfirm(e, "No")}
                    />
                </div>
        )
    };

    renderStatus = (e) => {
        const {data} = e;
        return (
                <div className={"display_row"}>
                    <Button
                        size={"small"}
                        color={data && data.InterviewStatusID.toLocaleUpperCase() === "SCHEDULED" ? "warning" : "default"}
                        label={"Scheduled"}
                        onClick={() => this.updateDataInterviewStatus(e, "SCHEDULED")}
                    />
                    <Divider direction={"vertical"}/>
                    <Button
                        size={"small"}
                        color={data && data.InterviewStatusID.toLocaleUpperCase() === "FINISHED" ? "success" : "default"}
                        label={"Finished"}
                        onClick={() => this.updateDataInterviewStatus(e, "FINISHED")}
                    />
                    <Divider direction={"vertical"}/>
                    <Button
                        size={"small"}
                        color={data && data.InterviewStatusID.toLocaleUpperCase() === "CANCEL" ? "danger" : "default"}
                        label={"Cancel"}
                        onClick={() => this.updateDataInterviewStatus(e, "CANCEL")}
                    />
                </div>
        )
    };

    renderInfo = (e) => {
        if (!e) return false;
        const {data} = e;
        return (
            <div className={"display_col pdt10 pdb10"}>
                <div className={"display_row align-center"}>
                    <Icon width={14} height={14} name={"phone"} className={'mgr2x'}/>
                    {data.Mobile || ""}
                </div>
                <div className={"display_row align-center"}>
                    <Icon width={14} height={14} name={"Mail"} className={'mgr2x'}/>
                    {data.Email || ""}
                </div>
            </div>
        );
    };

    renderInterviewDate = (e) => {
        if (!e) return false;
        const {data} = e;
        return (
            <div className={"display_col pdt10 pdb10"}>
                <div className={"display_row align-center"}>
                    <Icon width={14} height={14} className={'mgr1x'} name={"calendar"}/>
                    {Config.convertDate(data.InterviewDate, "", "DD/MM/YYYY", true)}
                </div>
                <div className={"display_row align-center"}>
                    <Icon width={14} height={14} className={'mgr1x'} name={"Clock"}/>
                        {Config.convertDate(data.InterviewDate, "", "LT", true)}
                </div>
            </div>
        );
    };

    renderInterviewPlace = (e) => {
        if (!e) return false;
        const {data} = e;
        return (
            <div className={"pdt10 pdb10 display_row align-center"}>
                <Icon name={"Marker"} className={'mgr1x'} width={14} height={14}/>
                {data.InterviewPlace || ""}
            </div>
        )
    };

    renderButtonCV = (e) => {
        return (
            <Button
                viewType={"text"}
                color={"info"}
                onClick={() => this.showCV(e)}
                label={Config.lang("xem_CV")}
            />
        )
    };

    renderInterViewImage = (e) => {
        const {data} = e;
        let listInterview = data && data.InterviewerList.split(",");
        listInterview = listInterview.map(item => item.trim());
        const listUsers = Config.getListUser(listInterview, "UserID");
        return (
            <div className={"display_row align-left"}>
                {listUsers.map((data, index) => (
                    <Image width={24} height={24} circular allowHover={false} src={data.UserPictureURL} key={index} />
                ))}
            </div>
        );

    };

    showCV = (e) => {
        const {data} = e.row;
        this.setState({
            rowData: data,
            mode: "popup",
            showW25F2022Popup: true
        });
    };

    onCloseModal = () => {
        this.setState({
            showW25F2022Popup: false
        });
    };

    onChangePage = (page) => {
        this.filter.skip = page * this.filter.limit;
        this.loadListCandicate();
    };

    onChangePerPage = (per) => {
        this.filter.skip = 0;
        this.filter.limit = per;
        this.loadListCandicate();
    };

    render() {
        let {classes, RecInfoID} = this.props;
        const {gridLoading, showW25F2022Popup, rowData, iPermission, dataListCandicates,
                  interviewerID,  candidateID, canConfirmStatusID, stageID,
                  interviewStatusID, interviewTypeID} = this.state;
        const {InterviewFileID} = rowData ? rowData : "";
        // group data grid by RecInfoID day
        _.get(dataListCandicates, "rows", []).forEach(d => {
            d.GroupID = moment(d.InterviewDate) >= moment() ? moment(d.InterviewDate).format("DD/MM/YYYY") : null;
        });
        const dataParam = {
            CandidateID: rowData?.CandidateID,
            RecInfoID: rowData?.RecInfoID,
            InterviewFileID: rowData?.InterviewFileID
        };
        const paramW25F1080GridLeft = {
            recInfoID: RecInfoID ?? "",
            interviewerID,
            candidateID,
            canConfirmStatusID,
            stageID,
            interviewStatusID,
            interviewTypeID,
        };

        return (
            <React.Fragment>
                <W25F2022Popup
                    openPopup={showW25F2022Popup}
                    data={rowData}
                    dataParam={dataParam}
                    keyID={InterviewFileID}
                    onClosePopup={this.onCloseModal}
                />
                <FormGroup className={"mgb0 mgt2x"}>
                    <Row>
                        <Col xs={12} sm={12} md={4} lg={4}>
                            <W25F2018GridLeft
                                ref={ref => this.refW25F2018Left = ref}
                                data={paramW25F1080GridLeft}
                                iPermission={iPermission}
                                loadListCandicate={this.loadListCandicate}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={8}>
                            <GridContainer
                                keyExpr={"RecInfoID"}
                                reference={ref => this.dataGrid = ref}
                                style={{border: 'none', paddingBottom: 37}}
                                dataSource={_.get(dataListCandicates, "rows", [])}
                                totalItems={_.get(dataListCandicates, "total", 0)}
                                skipPerPage={this.filter.skip}
                                height={Config.getHeightGrid() - 54}
                                elementAttr={{
                                    class: classes.focusedRow
                                }}
                                typePaging={"remote"}
                                loading={gridLoading}
                                rowAlternationEnabled={false}
                                showRowLines={true}
                                showBorders={false}
                                showColumnLines={false}
                                noDataText={Config.lang("No_data")}
                                onChangePage={this.onChangePage}
                                onChangePerPage={this.onChangePerPage}
                            >
                                <Column
                                    dataField={'GroupID'}
                                    groupIndex={0}
                                    allowSorting={true}
                                    sortOrder={"desc"}
                                    groupCellTemplate={(cellElement, cellInfo) => {
                                        let elements = document.getElementsByClassName("dx-datagrid-group-opened");
                                        for (let i = 0; i < elements.length; i++) {
                                            elements[i].style.display = "none";
                                        }
                                        let date = (cellInfo && cellInfo.value && moment(cellInfo.value, "DD-MM-YYYY").format("YYYY-MM-DD HH:mm:ss")) || null;
                                        this.nameDay = (date && moment(date).locale(Config.getLocalStorage("langDHR")).format('dddd')) || "";
                                        const html =
                                            `<div className="display_row align-center valign-middle"><Image width="20" src=${require('../../../../assets/images/icon-calendar.svg')} /><Typography style="margin-left: 10px">${cellInfo.displayValue ? `${cellInfo.text}(${this.nameDay})` : `${Config.lang("Da_dien_ra")} `}</Typography></div>`;

                                        return cellElement.innerHTML = html;
                                    }}
                                />
                                <Column
                                    caption={Config.lang("Ung_vien")}
                                    dataField={'CandidateName'}
                                    alignment={"left"}
                                    width={250}
                                    cellRender={this.renderEmpProfile}
                                />
                                <Column
                                    caption={Config.lang("Thong_tin_lien_he")}
                                    dataField={"Email"}
                                    cellRender={this.renderInfo}
                                    width={220}
                                />
                                <Column
                                    caption={Config.lang("Ung_vien_xac_nhan")}
                                    alignment={"center"}
                                    dataField={"CanConfirmStatusID"}
                                    width={250}
                                    cellRender={this.renderButtonConfirm}
                                />
                                <Column
                                    caption={Config.lang("Trang_thai")}
                                    alignment={"center"}
                                    dataField={"InterviewStatusID"}
                                    width={270}
                                    cellRender={this.renderStatus}
                                />
                                <Column
                                    caption={Config.lang("Giai_doan")}
                                    alignment={"left"}
                                    dataField={"StageName"}
                                    width={250}
                                />
                                <Column
                                    caption={Config.lang("Loai_phong_van")}
                                    alignment={"left"}
                                    dataField={'InterviewType'}
                                />
                                <Column
                                    caption={Config.lang("Thoi_gian")}
                                    dataField={"InterviewDate"}
                                    dataType={"date"}
                                    format={"dd/MM/yyyy"}
                                    editorOptions={{
                                        placeholder: "DD/MM/YYYY"
                                    }}
                                    alignment={"left"}
                                    width={150}
                                    cellRender={this.renderInterviewDate}

                                />
                                <Column
                                    caption={Config.lang("Dia_diem")}
                                    alignment={"left"}
                                    dataField={"InterviewPlace"}
                                    cellRender={this.renderInterviewPlace}
                                />
                                <Column
                                    caption={Config.lang("Nguoi_phong_van")}
                                    dataField={"InterviewerList"}
                                    cellRender={this.renderInterViewImage}
                                />
                                <Column
                                    caption={Config.lang("xem_CV")}
                                    alignment={"center"}
                                    width={150}
                                    cellRender={this.renderButtonCV}
                                />
                            </GridContainer>
                        </Col>
                    </Row>
                </FormGroup>
            </React.Fragment>
        )
    }
}

export default compose(connect(null, (dispatch) => ({
    generalActions: bindActionCreators(generalActions, dispatch),
    w25F2018Actions: bindActionCreators(W25F2018Actions, dispatch)
}), null, {forwardRef: true}), withStyles(styles))(W25F2018);
