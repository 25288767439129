/**
 * @copyright 2019 @ DigiNet
 * @author QUOCUY
 * @create 5/11/2020
 * @Example
 */

import React, { useState, useRef, useEffect } from "react";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    TextInput,
    Button,
    Dropdown,
    DatePicker,
    CircularProgress,
    Attachment,
    MoneyInput,
} from "diginet-core-ui/components";
import { Col, Row } from "react-bootstrap";
import Config from "../../../../config";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import * as W25F2040Actions from "../../../../redux/W2X/W25F2040/W25F2040_actions";
import * as generalActions from "../../../../redux/general/general_actions";
import MForm from "../../../common/form-material/form";
import moment from "moment";
import _ from "lodash";
import W25F2022 from "../W25F2022/W25F2022";
import CDN from "../../../CDN";

const styles = {
    loadingContainer: {
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        zIndex: 9999,
    },
};

const W25F2040 = props => {
    const {
        open,
        onClose,
        getSalaryCaption,
        getCoefficientCaption,
        getCboTeam,
        getCboProjects,
        getCboWorks,
        getCboTrialPeriod,
        getCboSalaryGroup,
        getCboDirectManager,
        RecInfoID,
        CandidateID,
        TransID,
        mode = 0,
        readOnly = false,
        iPermissions,
        fromScreen,
        FormID,
    } = props;
    const [loading, setLoading] = useState(false);
    const [dataVoucher, setDataVoucher] = useState({});
    const [cboRecruitmentFile, setCboRecruitmentFile] = useState([]);
    const [error, setError] = useState({});
    const [showW25F2022Popup, setShowW25F2022Popup] = useState(false);
    const [loadingAttachments, setLoadingAttachments] = useState(false);
    const [dataOldAttachments, setDataOldAttachments] = useState([]);
    const [isHideSalOfferRI, setIsHideSalOfferRI] = useState(0);

    const Language = useRef(Config.language || "84");
    const attachments = useRef([]);
    let deletedFile = useRef([]);

    const loadCboTeams = () => {
        const params = {
            DivisionID: Config.getDivisionID(),
            HostID: "",
            FormID: "W25F2040",
            Language: Config.language || "84",
        };
        props.generalActions.getCboTeam(params, error => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    const loadCboWorks = () => {
        const params = {
            Language: Language.current,
        };
        props.generalActions.getCboWorks(params, error => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    const loadCboProjects = () => {
        const params = {
            HostID: "",
            FormID: "W25F2040",
            Language: Language.current,
        };
        props.generalActions.getCboProjects(params, error => {
            if (error) {
                let message = error.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
        });
    };

    const loadCboSalaryGroup = () => {
        props.W25F2040Actions.getCboSalaryGroup((errors, data) => {
            if (errors) {
                Config.popup.show("ERROR", errors);
                return false;
            }
        });
    };

    const loadCboTrialPeriod = () => {
        const params = {
            FormID: "W25F2040",
            Language: Language.current,
        };
        props.W25F2040Actions.getCboTrialPeriod(params, error => {
            if (error) {
                let message = error.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
        });
    };

    const loadCboDirectManager = () => {
        const params = {
            DivisionID: Config.getDivisionID() || "",
            FormID: "W25F2040",
        };
        props.W25F2040Actions.getCboDirectManager(params, error => {
            if (error) {
                let message = error.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
        });
    };

    const loadCboRecruitmentFile = () => {
        const params = {
            FormID: "W25F2040",
            RecInfoID: RecInfoID,
        };
        props.W25F2040Actions.getCboRecruitmentFile(params, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            } else if (data) {
                setCboRecruitmentFile(data);
            }
        });
    };

    const loadCaption = () => {
        props.W25F2040Actions.loadSalaryCaption(errors => {
            if (errors) {
                let message = errors.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
        });
        props.W25F2040Actions.loadCoefficientCaption(errors => {
            if (errors) {
                let message = errors.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
        });
    };

    const loadData = () => {
        const params = {
            DivisionID: Config.getDivisionID() || "",
            FormID: FormID || "W25F2040",
            RecInfoID: RecInfoID,
            CandidateID: CandidateID,
            TransID: TransID,
            Mode: mode,
        };
        props.W25F2040Actions.loadVoucher(params, (errors, data) => {
            if (errors) {
                const message = errors.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            } else if (data) {
                data.TrialPeriod = data?.TrialPeriod || 2;
                setDataVoucher(data);
                if (data?.BeginDate) loadAttachments();
            }
            setLoading(false);
        });
    };

    const checkHideSalOfferRI = () => {
        props.W25F2040Actions.checkHideSalOfferRI((errors, data) => {
            if (errors) {
                const message = errors.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            } else if (data) {
                setIsHideSalOfferRI(data.IsHideSalOfferRI);
            }
            setLoading(false);
        });
    };

    useEffect(() => {
        if (open) {
            setLoading(true);
            loadCaption();
            loadCboTeams();
            loadCboWorks();
            loadCboProjects();
            loadCboTrialPeriod();
            loadCboDirectManager();
            loadCboSalaryGroup();
            loadCboRecruitmentFile();
            loadData();
            if (fromScreen === "W75F8000") {
                checkHideSalOfferRI();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    useEffect(() => {
        const { TrialDateFrom, BeginDate } = dataVoucher;
        if (BeginDate) {
            if (!TrialDateFrom) {
                _setDataForm("TrialDateFrom", BeginDate);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataVoucher?.BeginDate]);

    useEffect(() => {
        const { TrialDateFrom, TrialDateTo, TrialPeriod } = dataVoucher;
        if (TrialDateFrom) {
            if (TrialDateTo) {
                const _TrialPeriod = moment(TrialDateTo).diff(moment(TrialDateFrom), "days");
                _setDataForm("TrialPeriod", Math.round(_TrialPeriod / 30));
            } else if (TrialPeriod) {
                const NumDays = TrialPeriod * 30;
                const _TrialDateTo = moment(TrialDateFrom).add(NumDays, "days").format("YYYY-MM-DD");
                _setDataForm("TrialDateTo", _TrialDateTo);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataVoucher?.TrialDateFrom]);

    useEffect(() => {
        const { TrialDateFrom, TrialDateTo, TrialPeriod } = dataVoucher;
        if (TrialDateTo) {
            if (TrialDateFrom) {
                const _TrialPeriod = moment(TrialDateTo).diff(moment(TrialDateFrom), "days");
                _setDataForm("TrialPeriod", Math.round(_TrialPeriod / 30));
            } else if (TrialPeriod) {
                const NumDays = TrialPeriod * 30;
                const _TrialDateFrom = moment(TrialDateTo).subtract(NumDays, "days").format("YYYY-MM-DD");
                _setDataForm("TrialDateFrom", _TrialDateFrom);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataVoucher?.TrialDateTo]);

    useEffect(() => {
        const { TrialDateFrom, TrialDateTo, TrialPeriod } = dataVoucher;
        if (TrialPeriod) {
            if (TrialDateFrom) {
                const NumDays = TrialPeriod * 30;
                const _TrialDateTo = moment(TrialDateFrom).add(NumDays, "days").format("YYYY-MM-DD");
                _setDataForm("TrialDateTo", _TrialDateTo);
            } else if (TrialDateTo) {
                const NumDays = TrialPeriod * 30;
                const _TrialDateFrom = moment(TrialDateTo).subtract(NumDays, "days").format("YYYY-MM-DD");
                _setDataForm("TrialDateFrom", _TrialDateFrom);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataVoucher?.TrialPeriod]);
    const _setDataForm = (key, value, cb) => {
        if (!key) return false;
        setDataVoucher({ ...dataVoucher, [key]: value });
    };
    const handleChange = (key, e) => {
        if (!e) return false;
        switch (key) {
            case "BeginDate":
                _setDataForm(key, e?.value || "");
                break;
            case "TeamID":
                _setDataForm(key, e?.value || "");
                break;
            case "ProjectID":
                _setDataForm(key, e?.value || "");
                break;
            case "WorkID":
                _setDataForm(key, e?.value || "");
                break;
            case "TrialDateFrom":
                _setDataForm(key, e.value);
                break;
            case "TrialDateTo":
                _setDataForm(key, e.value);
                break;
            case "TrialPeriod":
                _setDataForm(key, e.value);
                break;
            case "DirectManagerID":
                _setDataForm(key, e?.value || "");
                break;
            case "SalEmpGroupID":
                _setDataForm(key, e?.value || "");
                break;
            case "RecruitmentFileID":
                _setDataForm(key, e?.value || "");
                break;
            case "DetailNotes":
                _setDataForm(key, e.target?.value || "");
                break;
            default:
                let newDataVoucher = dataVoucher;
                newDataVoucher[key.replace("BASE", "BaseSalary").replace("CE", "SalCoefficient")] = Number(
                    e?.value || ""
                );
                setDataVoucher(newDataVoucher);
                break;
        }
    };

    const setStateErrorText = value => {
        setError({ ...value });
        return Object.keys(value).length !== 0;
    };

    const _onClose = () => {
        setDataVoucher({});
        setDataOldAttachments([]);
        onClose();
    };

    const onSave = async () => {
        const validateRules = [
            {
                name: "BeginDate",
                rules: "isRequired",
                value: dataVoucher?.BeginDate,
            },
        ];
        const validateForm = MForm.formValidation(validateRules);
        if (Object.keys(validateForm).length > 0) {
            setStateErrorText(validateForm);
            return false;
        } else {
            setError({});
            const dataResUploadFile = await _uploadFile(attachments.current, true);
            const arrAttachments = _getAttachments(dataResUploadFile);
            const params = {
                TransID: TransID || "",
                FormID: "W25F2040",
                RecInfoID: RecInfoID || "",
                arrAttachment: JSON.stringify(arrAttachments),
                ...dataVoucher,
            };
            if (params?.RecruimentFileID === null) params.RecruimentFileID = "";
            if (mode === 0) {
                props.W25F2040Actions.add(params, async (errors, data) => {
                    if (errors) {
                        const message = errors.message || Config.lang("Loi_chua_xac_dinh");
                        Config.popup.show("INFO", message);
                        return false;
                    } else if (data) {
                        if (data.Status === 1) {
                            let message = data.Message || Config.lang("Loi_chua_xac_dinh");
                            Config.popup.show("INFO", message);
                        } else {
                            Config.notify.show("success", Config.lang("Luu_thanh_cong"), 2000);
                            _onClose();
                        }
                    }
                });
            } else if (mode === 1) {
                props.W25F2040Actions.edit(params, async (errors, data) => {
                    if (errors) {
                        const message = errors.message || Config.lang("Loi_chua_xac_dinh");
                        Config.popup.show("INFO", message);
                        return false;
                    } else if (data) {
                        if (data.Status === 1) {
                            let message = data.Message || Config.lang("Loi_chua_xac_dinh");
                            Config.popup.show("INFO", message);
                        } else {
                            Config.notify.show("success", Config.lang("Luu_thanh_cong"), 2000);
                            _onClose();
                        }
                    }
                });
            }
        }
        // setError({});
    };

    const getFullCandidateInfo = () => {
        let fullCandidateInfo = {};
        if (props?.fullCandidateInfo) {
            fullCandidateInfo = props?.fullCandidateInfo;
        } else if (props?.fullCandidateInfos) {
            fullCandidateInfo = props?.fullCandidateInfos.filter(x => x.CandidateID === dataVoucher?.CandidateID)?.[0];
        }
        return fullCandidateInfo;
    };

    const loadAttachments = () => {
        const param = {
            KeyID: TransID,
            TableName: "D25T2061"
        };
        setLoadingAttachments(true);
        props.generalActions.getAttachmentsByTransID(param, (err, data) => {
            setLoadingAttachments(false);
            if (err) {
                Config.popup.show('ERROR', err);
                return false;
            }
            if (data && data.length > 0) {
                setDataOldAttachments(data);
            }
        });
    };

    const onChangeAttachments = e => {
        attachments.current = e.allNewAttached ? e.allNewAttached : [];
        if (e.removedAttached && e.removedAttached.length > 0) {
            deletedFile.current = [...e.removedAttached];
            const _arrRemove = deletedFile.current.map(d => d.AttachmentID);
            setDataOldAttachments(
                dataOldAttachments.filter(att => {
                    return _arrRemove.indexOf(att.AttachmentID) < 0;
                })
            );
        }
    };

    const _uploadFile = (files, isAsync, cb) => {
        if (isAsync) {
            return CDN.uploadFileSync(files);
        } else {
            return CDN.uploadFile(files, null, cb);
        }
    };

    const _getAttachments = file => {
        const dataFile = _.get(file, "data.paths", []);
        const listAttachments = Config.helpers.getFileInfomations(dataFile);
        let arrAttachment = [];
        listAttachments.forEach(att => {
            arrAttachment.push({
                URL: att.url ? att.url : "",
                FileName: att.fileName ? att.fileName : "",
                FileSize: att.fileSize ? att.fileSize : "",
                FileExt: att.fileName ? att.fileName.split(".").pop() : "",
            });
        });
        if (dataOldAttachments && dataOldAttachments.length > 0) {
            arrAttachment = dataOldAttachments.concat(arrAttachment);
        }
        return arrAttachment;
    };

    return (
        <>
            <W25F2022
                openPopup={showW25F2022Popup}
                data={{
                    ...{
                        RecInfoID: RecInfoID || "",
                        InterviewFileID: dataVoucher?.InterviewFileID || "",
                        CandidateID: dataVoucher?.CandidateID || "",
                        ...getFullCandidateInfo(),
                        isReadOnly: true,
                    },
                }}
                onClosePopup={() => {
                    setShowW25F2022Popup(false);
                }}
            />
            <Modal
                onClose={_onClose}
                open={open}
                pressESCToClose={false}
                moveable={false}
                dragAnyWhere={false}
                moveOutScreen={false}
                width={696}
                top={50}
                zIndex={1300}
            >
                <ModalHeader title={Config.lang("De_xuat_offer")} />
                <ModalBody style={loading ? { overflow: "hidden" } : {}}>
                    {loading && (
                        <div style={styles.loadingContainer}>
                            <CircularProgress size={"sm"} />
                        </div>
                    )}
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <TextInput
                                required
                                readOnly
                                label={Config.lang("Ten_nhan_su")}
                                placeholder={Config.lang("Ten_nhan_su")}
                                value={dataVoucher?.CandidateName || ""}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <DatePicker
                                required
                                clearAble
                                label={Config.lang("Ngay_vao_lam_du_kien")}
                                placeholder={Config.lang("Ngay_vao_lam_du_kien")}
                                value={dataVoucher?.BeginDate || ""}
                                onChange={e => handleChange("BeginDate", e)}
                                error={error && error["BeginDate"]}
                                validates={["isRequired"]}
                                displayFormat={"DD/MM/YYYY"}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <TextInput
                                required
                                readOnly
                                label={Config.lang("Vi_tri")}
                                placeholder={Config.lang("Vi_tri")}
                                value={dataVoucher?.RecpositionName || ""}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <TextInput
                                required
                                readOnly
                                label={Config.lang("Phong_ban")}
                                placeholder={Config.lang("Phong_ban")}
                                value={dataVoucher?.DepartmentName || ""}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <Dropdown
                                clearAble
                                dataSource={getCboTeam || []}
                                label={Config.lang("To_nhom")}
                                displayExpr={"TeamName"}
                                valueExpr={"TeamID"}
                                placeholder={Config.lang("To_nhom")}
                                value={dataVoucher?.TeamID || ""}
                                onChange={e => handleChange("TeamID", e)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <Dropdown
                                clearAble
                                dataSource={getCboProjects?.rows || []}
                                label={Config.lang("Du_an")}
                                displayExpr={"ProjectName"}
                                valueExpr={"ProjectID"}
                                placeholder={Config.lang("Du_an")}
                                value={dataVoucher?.ProjectID || ""}
                                onChange={e => handleChange("ProjectID", e)}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <Dropdown
                                clearAble
                                dataSource={getCboWorks || []}
                                label={Config.lang("Cong_viec")}
                                displayExpr={"WorkName"}
                                valueExpr={"WorkID"}
                                placeholder={Config.lang("Cong_viec")}
                                value={dataVoucher?.WorkID || ""}
                                onChange={e => handleChange("WorkID", e)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <Dropdown
                                required
                                dataSource={getCboTrialPeriod || []}
                                label={Config.lang("Thoi_gian_thu_viec")}
                                displayExpr={"Name"}
                                valueExpr={"ID"}
                                placeholder={Config.lang("Thoi_gian_thu_viec")}
                                value={"" + dataVoucher?.TrialPeriod || 2}
                                onChange={e => handleChange("TrialPeriod", e)}
                                error={error && error["TrialPeriod"]}
                                validates={["isRequired"]}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <Dropdown
                                clearAble
                                dataSource={getCboDirectManager || []}
                                label={Config.lang("Nguoi_quan_ly_truc_tiep")}
                                displayExpr={"DirectManagerName"}
                                valueExpr={"DirectManagerID"}
                                placeholder={Config.lang("Nguoi_quan_ly_truc_tiep")}
                                value={dataVoucher?.DirectManagerID || ""}
                                onChange={e => handleChange("DirectManagerID", e)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <DatePicker
                                clearAble
                                label={Config.lang("Ngay_bat_dau_thu_viec")}
                                placeholder={Config.lang("Ngay_bat_dau_thu_viec")}
                                value={dataVoucher?.TrialDateFrom || ""}
                                max={dataVoucher?.TrialDateTo || ""}
                                onChange={e => handleChange("TrialDateFrom", e)}
                                displayFormat={"DD/MM/YYYY"}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <DatePicker
                                clearAble
                                label={Config.lang("Ngay_ket_thuc_thu_viec")}
                                placeholder={Config.lang("Ngay_ket_thuc_thu_viec")}
                                value={dataVoucher?.TrialDateTo || ""}
                                min={dataVoucher?.TrialDateFrom || ""}
                                onChange={e => handleChange("TrialDateTo", e)}
                                displayFormat={"DD/MM/YYYY"}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Dropdown
                                clearAble
                                dataSource={cboRecruitmentFile || []}
                                label={Config.lang("De_xuat_tuyen_dung")}
                                displayExpr={"RecruitmentFileName"}
                                valueExpr={"RecruitmentFileID"}
                                placeholder={Config.lang("De_xuat_tuyen_dung")}
                                value={dataVoucher?.RecruitmentFileID || ""}
                                onChange={e => handleChange("RecruitmentFileID", e)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <TextInput
                                label={Config.lang("Ghi_chu")}
                                placeholder={Config.lang("Ghi_chu")}
                                value={dataVoucher?.DetailNotes || ""}
                                onChange={e => handleChange("DetailNotes", e)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Accordion expand={mode === 0} disabled={!!isHideSalOfferRI}>
                            <AccordionSummary>{Config.lang("Thong_tin_luong")}</AccordionSummary>
                            <AccordionDetails>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Dropdown
                                            clearAble
                                            label={Config.lang("Nhom_luong")}
                                            dataSource={getCboSalaryGroup || []}
                                            displayExpr={"{SalEmpGroupID}-{SalEmpGroupName}"}
                                            valueExpr={"SalEmpGroupID"}
                                            renderSelectedItem="SalEmpGroupName"
                                            placeholder={Config.lang("Chon")}
                                            value={dataVoucher?.SalEmpGroupID || ""}
                                            onChange={e => handleChange("SalEmpGroupID", e)}
                                        />
                                    </Col>
                                    {getSalaryCaption &&
                                        getSalaryCaption.map(
                                            (items, index) =>
                                                getSalaryCaption[index].Disabled === 0 && (
                                                    <Col xs={12} sm={12} md={6} lg={6} key={index}>
                                                        <MoneyInput
                                                            label={items.CaptionName}
                                                            value={Config.helpers.getObjectValue(
                                                                dataVoucher,
                                                                `BaseSalary${getSalaryCaption[index].Code.substr(-2)}`,
                                                                ""
                                                            )}
                                                            onChange={e => handleChange(items.Code, e)}
                                                            decimalDigit={4}
                                                            allowZero
                                                            disabledNegative
                                                            thousandSeparator={","}
                                                        />
                                                    </Col>
                                                )
                                        )}
                                    {getCoefficientCaption &&
                                        getCoefficientCaption.map(
                                            (items, index) =>
                                                getCoefficientCaption[index].Disabled === 0 && (
                                                    <Col xs={12} sm={12} md={6} lg={6} key={index}>
                                                        <MoneyInput
                                                            label={items.CaptionName}
                                                            value={Config.helpers.getObjectValue(
                                                                dataVoucher,
                                                                `SalCoefficient${getCoefficientCaption[
                                                                    index
                                                                ].Code.substr(-2)}`,
                                                                ""
                                                            )}
                                                            onChange={e => handleChange(items.Code, e)}
                                                            decimalDigit={4}
                                                            allowZero
                                                            disabledNegative
                                                            thousandSeparator={","}
                                                        />
                                                    </Col>
                                                )
                                        )}
                                </Row>
                            </AccordionDetails>
                        </Accordion>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Attachment
                                data={dataOldAttachments}
                                onChange={onChangeAttachments}
                                disabled={loadingAttachments}
                                uploadErrorInfo={{
                                    existingFile: Config.lang("File_da_duoc_dinh_kem"),
                                    maxFile: Config.lang("File_vuot_qua_so_luong_cho_phep"),
                                    maxSize: Config.lang("File_vuot_qua_dung_luong_cho_phep"),
                                    fileType: Config.lang("File_khong_dung_dinh_dang"),
                                }}
                            />
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter style={{ justifyContent: "space-between" }}>
                    <Button
                        viewType={"text"}
                        color={"info"}
                        style={{ padding: 0 }}
                        text={Config.lang("Xem_CV")}
                        onClick={() => setShowW25F2022Popup(true)}
                    />
                    <Button
                        viewType={"filled"}
                        color={"info"}
                        style={{ textTransform: "uppercase", minWidth: 105 }}
                        text={Config.lang("Luu")}
                        onClick={onSave}
                        disabled={readOnly || iPermissions?.W25F2040 < 2}
                    />
                </ModalFooter>
            </Modal>
        </>
    );
};

export default compose(
    connect(
        state => ({
            getSalaryCaption: state.W25F2040.getSalaryCaption,
            getCoefficientCaption: state.W25F2040.getCoefficientCaption,
            getCboTrialPeriod: state.W25F2040.getCboTrialPeriod,
            getCboDirectManager: state.W25F2040.getCboDirectManager,
            getCboSalaryGroup: state.W25F2040.getCboSalaryGroup,
            getCboTeam: state.general.getCboTeam,
            getCboProjects: state.general.getCboProjects,
            getCboWorks: state.general.getCboWorks,
        }),
        dispatch => ({
            generalActions: bindActionCreators(generalActions, dispatch),
            W25F2040Actions: bindActionCreators(W25F2040Actions, dispatch),
        })
    )
)(W25F2040);
